<template>
    <v-container fluid>
        <v-row dense>
            <v-col cols="12" md="12">
                <v-card :elevation="1">
                    <v-card-text class="py-1">
                        <v-row dense>
                            <v-col cols="12" md="2">
                                <v-combobox label="Department" dense outlined hide-details
                                    :items="$store.getters['department/departments']" v-model="department"
                                    item-text="name" item-value="id"
                                    :loading="$store.getters['department/loadingDepartments']" @focus="getDepartments"
                                    class="custom-form"></v-combobox>
                            </v-col>

                            <v-col md="3">
                                <v-combobox label="Section" dense outlined hide-details clearable :items="sections"
                                    v-model="section" item-text="name" item-value="id"
                                    :loading="$store.getters['section/loadingSections']"
                                    class="custom-form"></v-combobox>
                            </v-col>
                            <v-col md="3">
                                <v-combobox label="Line" dense outlined hide-details clearable :items="lines"
                                    v-model="line" item-text="name" item-value="id"
                                    :loading="$store.getters['line/loadingLines']" class="custom-form"></v-combobox>
                            </v-col>

                            <v-col cols="12" md="2">
                                <v-combobox label="Designation" dense outlined hide-details
                                    :items="$store.getters['designation/designations']" v-model="designation"
                                    item-text="name" item-value="id"
                                    :loading="$store.getters['designation/loadingDesignations']"
                                    @focus="getDesignations" class="custom-form"></v-combobox>
                            </v-col>

                            <v-col cols="12" md="2">
                                <v-combobox label="Shift" dense outlined hide-details
                                    :items="$store.getters['shift/shifts']" v-model="shift" item-text="name"
                                    item-value="id" :loading="$store.getters['shift/loadingShifts']" @focus="getShifts"
                                    class="custom-form"></v-combobox>
                            </v-col>

                            <v-col cols="12" md="2">
                                <v-combobox label="Employee" dense outlined hide-details v-model="employee"
                                    :items="$store.getters['employee/employees']"
                                    :loading="$store.getters['employee/loadingEmployees']" item-text="display_name"
                                    item-value="id" class="mb-1 custom-form" @focus="getEmployees"></v-combobox>
                            </v-col>

                            <v-col cols="12" md="2">
                                <v-menu :close-on-content-click="false" transition="scale-transition" offset-y
                                    min-width="auto">
                                    <template v-slot:activator="{ on }">
                                        <v-text-field dense outlined hide-details v-model="date" label="Date" v-on="on"
                                            class="custom-form"></v-text-field>
                                    </template>
                                    <v-date-picker v-model="date"></v-date-picker>
                                </v-menu>
                            </v-col>

                            <v-col cols="6" md="1">
                                <v-btn elevation="1" small color="primary" dark style="margin-top:-2px"
                                    :loading="loading" @click="getResults">
                                    Search </v-btn>
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
        <v-row v-if="results.length > 0">
            <v-col cols="12" md="12" class="custom-form py-1">
                <v-card :elevation="1">
                    <v-toolbar dense color="white" :elevation="1" height="40px">
                        <v-spacer></v-spacer>
                        <v-text-field dense outlined hide-details v-model="inTime" label="In" type="time"
                            style="max-width:100px;"></v-text-field>

                        <v-text-field dense outlined hide-details v-model="outTime" label="Out" type="time" class="mx-2"
                            style="max-width:100px;"></v-text-field>

                        <v-btn small color="info" :elevation="1" @click="setTime">Set</v-btn>

                    </v-toolbar>

                    <v-card-text class="pa-0">
                        <v-simple-table dense>
                            <template v-slot:default>
                                <thead>
                                    <tr>
                                        <th class="text-left">E.Id</th>
                                        <th class="text-left">Name</th>
                                        <th class="text-left">Phone</th>
                                        <th class="text-left">Designation</th>
                                        <th class="text-left">Department</th>
                                        <th class="text-left">Shift</th>
                                        <th class="text-center">In</th>
                                        <th class="text-center">Out</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(employee, sl) in results" :key="sl">
                                        <td>{{ employee.emp_id }}</td>
                                        <td>{{ employee.name }}</td>
                                        <td>{{ employee.phone }}</td>
                                        <td>{{ employee.designation_name }}</td>
                                        <td>{{ employee.department_name }}</td>
                                        <td>{{ employee.shift_name }}</td>
                                        <td style="width:120px;">
                                            <v-text-field type="time" dense outlined hide-details
                                                v-model="employee.in_time" style="max-width:100px;"></v-text-field>
                                        </td>
                                        <td style="width:120px;">
                                            <v-text-field type="time" dense outlined hide-details
                                                v-model="employee.out_time" style="max-width:100px;"></v-text-field>
                                        </td>
                                    </tr>
                                </tbody>
                            </template>
                        </v-simple-table>
                    </v-card-text>
                </v-card>
            </v-col>
            <v-col cols="12" md="12" class="text-right">
                <v-btn height="30px" dark color="info" class="mr-1" :elevation="1" @click="saveAttendance">Save</v-btn>
                <v-btn height="30px" dark color="deep-orange" :elevation="1">Cancel</v-btn>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
import utility from '../../mixins/utility.mixin';
export default {
    mixins: [utility],
    data() {
        return {
            date: this.toISOLocal().substr(0, 10),
            inTime: null,
            outTime: null,
            department: null,
            sections: [],
            section: null,
            lines: [],
            line: null,
            designation: null,
            shift: null,
            employee: null,
            results: [],
            loading: false
        }
    },
    watch: {
        date() {
            this.results = [];
        },
        department(department) {
            this.getSections();
        },
        section(section) {
            this.getLines();
        }
    },
    methods: {
        getDepartments() {
            this.department = null;
            this.$store.dispatch('department/getDepartments');
        },
        async getSections() {
            this.selectedSection = null;
            this.sections = [];
            if (this.department && this.department.id != undefined) {
                this.sections = await this.$store.dispatch('section/getSections', {
                    apiParams: {
                        departmentId: this.department.id
                    },
                    options: {
                        returnData: true,
                    }
                });
            }
        },
        async getLines() {
            this.selectedLine = null;
            this.lines = [];
            if (this.section && this.section.id != undefined) {
                this.lines = await this.$store.dispatch('line/getLines', {
                    apiParams: {
                        sectionId: this.section.id
                    },
                    options: {
                        returnData: true,
                    }
                });
            }
        },
        getDesignations() {
            this.designation = null;
            this.$store.dispatch('designation/getDesignations');
        },
        getShifts() {
            this.shift = null;
            this.$store.dispatch('shift/getShifts');
        },
        getEmployees() {
            this.employee = null;
            this.$store.dispatch('employee/getEmployees', {
                apiParams: {
                    departmentId: this.department?.id ?? null,
                    sectionId: this.section?.id ?? null,
                    lineId: this.line?.id ?? null,
                    designationId: this.designation?.id ?? null,
                    shiftId: this.shift?.id ?? null,
                },
            })
        },
        async getResults() {
            this.inTime = null;
            this.outTime = null;
            this.results = [];
            this.loading = true;

            this.results = await this.$store
                .dispatch('attendance/getEmployeeAttendances', {
                    apiParams: {
                        departmentId: this.department?.id ?? null,
                        sectionId: this.section?.id ?? null,
                        lineId: this.line?.id ?? null,
                        designationId: this.designation?.id ?? null,
                        shiftId: this.shift?.id ?? null,
                        employeeId: this.employee?.id ?? null,
                        date: this.date,
                    },
                    options: {
                        returnData: true
                    }
                });

            this.loading = false;
        },

        setTime() {
            this.results = this.results.map(employee => {
                employee.in_time = this.inTime;
                employee.out_time = this.outTime;
                return employee;
            })
        },

        async saveAttendance() {
            let data = {
                date: this.date,
                attendances: this.results
            }

            let isSuccess = await this.$store.dispatch('attendance/saveAttendance', data);

            if (isSuccess) {
                this.reset();
            }
        },
        reset() {
            this.inTime = null;
            this.outTime = null;
            this.department = null;
            this.designation = null;
            this.shift = null;
            this.employee = null;
            this.results = [];
            this.date = this.toISOLocal().substr(0, 10);
        }
    },
}
</script>